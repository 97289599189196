@import '~antd/dist/antd.css';

/* .App {
  text-align: center;
  height: 100vh;
} */

.App {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Home-Layout {
  width: 100%;
  height: 100vh;
  /* background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover; */

  /* background-image: url("bkk1.jpg"); */
  /* backdrop-filter: blur(5px); */

  /* filter: blur(8px);
  -webkit-filter: blur(8px); */

  /* background-image: url(https://uploads.codesandbox.io/uploads/user/6dfa60b6-9433-40e5-b36d-9b8d2ad54c0c/zwoa-back3.jpg); */
  background-image: url(https://life-easy.transcode.co.th/assets/bkk1-min-blur.jpg);
  background-size: cover;
  background-position: center;

  -moz-background-size: cover;
  -webkit-background-size: cover;
  /* filter: blur(8px); */
  
}

.Home-Content {
  /* margin-top: 112px; */
  /* margin-top: 68px; */
  margin-top: 0px;
  height: 100%;
}

.Home-Content-Container {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Home-Footer {
  text-align: 'center';
  margin-bottom: 0px;
}

.site-page-header {
  /*border: 1px solid rgb(235, 237, 240);*/
}
